@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Regular/FoundersGrotesk-Regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Regular/FoundersGrotesk-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.Project-viewer {
  font-family: 'Founders Grotesk';
  font-size:18px;
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  background:white;
  z-index:100000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1.5s, opacity 0.5s ease-out;
  pointer-events:none;
  overflow:scroll;
  transition-delay: 0s, 200ms;
}

.Project-viewer.true{
  display:block;
  visibility: visible;
  opacity: 1;
  pointer-events:all;
  transition: visibility 0.3s, opacity 0.3s ease-out;
  transition-delay: 0s, 000ms;
}

.Project-viewer .Project-header{
  visibility: hidden;
  opacity: 0;
  margin-top:150px;
  transition: visibility 0.25s, opacity 0.25s, margin-top 0.7s ease-in;
  transition-delay: 0s, 150ms;
}

.Project-viewer.true .Project-header{
  transition: visibility 0.35s, opacity 0.35s, margin-top 0.7s ease-out;
  transition-delay: 0s, 300ms;
  margin-top:90px;;
  visibility: visible;
  opacity: 1;
}

.Project-viewer .Project-section{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.25s, opacity 0.25s ease-in;
  transition-delay: 0s, 000ms;
}

.Project-viewer.true .Project-section{
  transition: visibility 0.35s, opacity 0.35s ease-out;
  transition-delay: 0s, 450ms;
  visibility: visible;
  opacity: 1;
}

.Project-container{
  position:relative;
  z-index:10;
}

.Project-viewer .Background{
  position:fixed;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index:0;
  cursor: url(img/back_cursor.png) 60 20, auto;
}

.Project-header{
  width:100%;
  margin: 50px 0px;
  border-radius:6px;
  background:#DD1B82;
  height:300px;
  padding:25px 40px;
  box-sizing:border-box;
  box-shadow: 0px 4px 60px rgba(0,0,0,.15);
  color:white;
  position:relative;
}

.Project-header .Img{
  position: absolute;
  right: 20px;
  bottom: 20px;
  top: 20px;
  height: calc(100% - 42px);
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 5px;
}

.Project-header .Description{
  width:50%;
}

.Project-viewer .Logo{
  width:30px;
  height:30px;
  background-size:cover;
  background-position: center center;
  background-repeat:no-repeat;
  display:inline-block;
  border: 1px solid #F0F0F0;
  border-radius:100px;
  margin-right:10px;
  position:relative;
  top:8px;
  transition: all .5s;
  background-color:white;
}

.Project-viewer .Title{
  font-family: 'Cabin', sans-serif;
  font-weight:500;
  font-size:1.2em;
  margin-bottom:30px;
}

.Project-viewer .Description{

}






/*************************************************/
/**************** MAIN PAGE MENU *****************/
/*************************************************/

.Project-section{
  width:100%;
  margin: 50px 0px;
  border-radius:6px;
  background:white;
  padding:40px 40px;
  box-sizing:border-box;
  box-shadow: 0px 4px 60px rgba(0,0,0,.15);
  color:black;
  overflow:hidden;
}

.Project-section iframe{
  border-radius:4px;
  overflow:hidden;
  margin-bottom:40px;
}

.Project-viewer .Page-container{
  max-width:1000px;
}



/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){
  
}

@media(max-width:1200px){
  .Project-viewer .Page-container{
    max-width:700px;
  }
  .Project-header{
    height:auto;
    overflow:hidden;
    padding-bottom:60px;
  }
  .Project-header .Description {
    width: 100%;
    max-width:400px;
    margin:auto;
    margin-top:30px;
  }
  .Project-header .Img{
    position:relative;
    margin:auto;
    top:auto;
    right:auto;
    bottom:auto;
    width:100%;
    height:auto;
    display:block;
    max-width:400px;
  }
  .Project-section iframe{
    height:350px;
  }
}

@media(max-width:1000px){
  
}

@media(max-width:800px){
  .Project-section iframe{
    margin-bottom:0px;
  }
  .Project-header .Name{
    font-size: .85em;
    line-height: 2em;
  }
  
  .Project-section .Half-section{
    margin-top:30px;
  }
}