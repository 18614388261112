@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Bold/FoundersGrotesk-Bold.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Bold/FoundersGrotesk-Bold.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Bold/FoundersGrotesk-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Medium/FoundersGrotesk-Medium.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Medium/FoundersGrotesk-Medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Medium/FoundersGrotesk-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: medium;
  font-style: normal;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Light/FoundersGrotesk-Light.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Light/FoundersGrotesk-Light.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Light/FoundersGrotesk-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Founders Grotesk";
  src: url("./fonts/Founders_Regular/FoundersGrotesk-Regular.eot"); /* IE9 Compat Modes */
  src: url("./fonts/Founders_Regular/FoundersGrotesk-Regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.otf") format("opentype"), /* Open Type Font */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.svg") format("svg"), /* Legacy iOS */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Founders_Regular/FoundersGrotesk-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Waltograph';
  src: url('./fonts/Waltograph/waltograph42-webfont.woff2') format('woff2'),
       url('./fonts/Waltograph/waltograph42-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin Sketch';
  src: url('./fonts/Cabin_Sketch/cabinsketch-bold-webfont.woff2') format('woff2'),
       url('./fonts/Cabin_Sketch/cabinsketch-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'Cabin Sketch';
  src: url('./fonts/Cabin_Sketch/cabinsketch-regular-webfont.woff2') format('woff2'),
       url('./fonts/Cabin_Sketch/cabinsketch-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Cabin';
  src: url('./fonts/Cabin/cabin-bold-webfont.woff2') format('woff2'),
       url('./fonts/Cabin/cabin-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'Cabin';
  src: url('./fonts/Cabin/cabin-regular-webfont.woff2') format('woff2'),
       url('./fonts/Cabin/cabin-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


.App {
  font-family: 'Founders Grotesk';
  font-size:18px;
  font-weight:normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

html {
  scroll-behavior: smooth;
  color:#2F373F;
}

body.project-open{
  overflow:hidden;
}

.App-header {
  background: linear-gradient(0.2turn, #0990F9, #6FBBFF);
  height:calc(100vh - 120px);
  min-height:600px;
  color: white;
  text-align:left;
  position:relative;
  overflow:hidden;
}

#videoWrap{
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  position:absolute;
  z-index:0;
}

#videoFade{
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  position:absolute;
  z-index:1;
  background:linear-gradient(0turn, #0990F9,rgba(255,255,255,0));
  box-shadow: inset 0 0 500px 130px #0970E4;
  opacity:.7;
}

#App-header-video{
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: calc(100vh - 120);
  position:absolute;
  z-index:0;
  background-size: cover;
  background-position:top center;
  overflow:hidden;
  opacity:.45;
  transition: opacity 1.2s!important;
  transition-delay:1s;
}

#App-header-video.wait{
  opacity:0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Cover-img{
  position:absolute;
  right:175px;
  top:180px;
  z-index:0;
  width:550px;
}

.red{
  background:red;
  width:100px;
  height:100px;
}


.Page-container{
  margin:auto;
  width:100%;
  height:100%;
  max-width:1200px;
  display: block;
  position:relative;
  margin-top:0px;
  z-index:10;
}

.App-logo {
  height: 55px;
  pointer-events: none;
  background: transparent;
  position:absolute;
  top:80px;
  left:0px;
}

.Tag-line{
  pointer-events: none;
  background: transparent;
  position:absolute;
  top:135px;
  left:0px;
  display:none!important;
}

.hello_code, .hello_brand{
  color:#0784F8;
}
.hello_code{
  font-family: monospace;
  font-weight:500;
  font-size: 1.05em;
  display: inline-block;
  margin: 6px;
}
.hello_brand{
  font-family: 'Waltograph';
  font-weight:500;
  font-size:1.3em;
  letter-spacing: 1px;
  display: inline-block;
  margin: 6px;
}
.code_underscore{
  opacity:1;
  animation: blinker 1.2s infinite;
  animation-delay:.9s;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.Intro-text{
  background: transparent;
  position:absolute;
  bottom:60px;
  left:0px;
  color:#FFF;
}

.Intro-text p{
  position:absolute;
  left:550px;
  bottom:0px;
  width:410px;
  font-size:1.35em;
  border-left:2px solid #FFF;
  padding-top:0px;
  padding-left:65px;
  line-height:1.38em;
}

.Intro-text b{
  display:block;
  position:absolute;
  bottom:28px;
  font-size:1.9em;
  width:570px;
  line-height:1.4em;
  font-family:cabin, sans-serif;
}



/*************************************************/
/**************** MAIN PAGE MENU *****************/
/*************************************************/


.App-menu{
  height:90px;
  width:100%;
  margin:0px;
  padding:0px;
  color: #0784F8;
  border-bottom: 1px solid #D0F7F9;
  overflow:scroll;
  user-select:none;
  font-size:1.25em;
  background:white;
  transition: height .5s, box-shadow .5s;
}

#Nav_wrapper{
  height:90px;
  width:100%;
}

#Main_nav.sticky{
  position:fixed;
  top:0px;
  z-index:100;
  height:80px;
  box-shadow:0px 4px 0px rgba(0,0,0,.03);
}

#Main_nav.sticky .Menu-item{
  margin-top:8px;
  line-height:72px;
}

.App-menu .Menu-item, .App-menu .Menu-end{
  padding:0px 40px;
  float:left;
  margin-top:5px;
  line-height:85px;
  transition: text-decoration-color .8s, line-height .5s, margin .5s;
  text-decoration:underline;
  text-decoration-color:#FFF;
}

.App-menu .Menu-item:first-child{
  padding-left:0px!important;
}

.App-menu .Menu-item:last-child{
  padding-right:0px!important;
}

.App-menu .Menu-item:hover{
  text-decoration:underline;
  text-decoration-color:#0784F8;
  cursor:pointer;
}

.App-menu .Menu-item.active{
  text-decoration:underline; 
  text-decoration-color:#59E9FC;
}

.App-menu .Menu-end{
  float:right;
  padding:0px!important;
}

.Menu-inner{
  width:100%;
  min-width:720px;
}







/*************************************************/
/**************** MAIN PAGE BODY *****************/
/*************************************************/

/*@keyframes FadeIn { 
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity:1;
    transform: scale(1);
  }
}


  animation: FadeIn .3s linear;
  animation-fill-mode: both;*/

#App-body .Body-header, #App-body h2{
  opacity:1;
  transition: opacity .25s;
}

#App-body.faded .Body-header, #App-body.faded h2{
  opacity:0;
}

.Project{
  transition: opacity .3s;
}

#App-body.faded .Project{
  opacity:0!important;
  
}

#App-body .Project:nth-child(1){
  transition-delay:.06s;
}
#App-body .Project:nth-child(2){
  transition-delay:.12s;
}
#App-body .Project:nth-child(3){
  transition-delay:.18s;
}
#App-body .Project:nth-child(4){
  transition-delay:.24s;
}
#App-body .Project:nth-child(5){
  transition-delay:.3s;
}
#App-body .Project:nth-child(6){
  transition-delay:.36s;
}

.Project .Name{
  font-size:.9em;
}




.App-body{
  padding:80px 0px;
  padding-bottom:20px;
  float:left;
  width:100%;
}

h1{
  font-size:48px;
  /*font-family:'Roboto', sans-serif;*/
  font-family:'Fredericka the great', sans-serif;
  font-family:'Cabin Sketch', sans-serif;
  font-family:'Bebas Neue', sans-serif;
  font-family:'Dancing Script', sans-serif;
  font-family:'Cabin Sketch', sans-serif;
  font-weight:500;
  color:#0784F8;
  /*text-shadow:1px 1px 0px white, 2px 2px 0px #A6F5FF;*/
  margin: 0px;
}

.Half-section{
  width:50%;
  float:left;
  box-sizing:border-box;
}

.Half-section:first-child{
  padding-right:40px;
}

.Half-section:last-child{
  padding-left:40px;
}

.Half-section h2{
  margin-top:0px;
}

h2{
  font-size:1.35em;
  font-family:'Cabin', sans-serif;
  font-weight:400;
  padding-top:5px;
}

ul{
  padding:0px;
}

li{
  list-style-type:none;
  width:100%;
  clear:left;
}

.List .List-number{
  width:20px;
  height:20px;
  border-radius:100px;
  background:black;
  text-align:center;
  color:white;
  line-height:10px;
  padding-top:8px;
  font-size:16px;
  font-weight:bold;
  box-sizing:border-box;
  float:left;
}

.List .Name{
  float: left;
  margin-left: 10px;
  font-weight: bold;
  padding-top: 3px;
  clear: right;
  max-width: calc(100% - 30px);
}

.List .Description{
  padding-top:4px;
  padding-bottom:10px;
  font-size:14px;
  margin-left:30px;
}

.Quote-text{
  width: calc(100% - 100px);
  max-width: 1020px;
  margin: auto;
  margin-top: 200px;
  font-size: 1.3em;
  padding: 50px 70px;
  text-align: justify;
  font-family:cabin;
  font-weight:bold;
  position:relative;
  box-sizing:border-box;
}

.Quote-text .blue{
  color:#0784F8;
}

.Quote-text .leftCorner{
  width:40px;
  height:10px;
  border-top: 1px solid #0784F8;
  border-left: 1px solid #0784F8;
  top:20px;
  left:40px;
  position:absolute;
}

.Quote-text .rightCorner{
  width:40px;
  height:10px;
  border-bottom: 1px solid #222;
  border-right: 1px solid #222;
  bottom:20px;
  right:40px;
  position:absolute;
}

.Half-section.Team, .Half-section.About{
  padding:0px;
  width:100%;
}

.Half-section.Team p, .Half-section.About p{
  width:50%;
  line-height: 1.28em;
}

.Half-section.Team p>i{
  font-size:1.6em;
}

.Body-header{
  overflow:hidden;
  width:100%;
}

/*
.Product-sketch{
  width:500px;
  position:absolute;
  top:-10px;
  right:50px;
}*/

.Product-sketch{
  transform: scale(1.05);
  width:1040px;
  position:absolute;
  top:-30px;
  right:-70px;
  /*pointer-events:none;*/
}




/*************************************************/
/************** MAIN PAGE SECTIONS ***************/
/*************************************************/

.Work_previews .List li{
  margin-bottom:10px;
}

.Work_previews .left-section{
  width:33.33%;
  float:left;
}
.Work_previews .right-section{
  width:calc(66.66% - 60px);
  padding-left:60px;
  float:left;
}

.Body-projects .Project.Col_2{
  width: calc(50% - 30px);
  float:left;
  padding:30px;
  padding-bottom:50px;
  padding-top:20px;
}

.Body-projects .Project.Col_2:nth-child(2n+1){
  padding-left:0px!important;
  padding-right:30px;
  clear:left;
}
.Body-projects .Project.Col_2:nth-child(2n+2){
  padding-right:0px;
  padding-left:30px;
  clear:right;
}

.Work_previews{
  float: left;
  width: 100%;
  padding-top: 60px;
  opacity:1;
  padding-bottom:60px;
}

.h1_2{
  font-size:2.1em;
  padding-bottom:10px;
  width:33.33%;
  float:left;
}

.Work_previews h2{
  width:calc(66.66% - 60px);
  float:left;
  margin:0px;
  padding-top:1px;
  clear:right;
  padding-left:60px;
  font-weight:bold;
  color:#e0e0e0;
  display:none;
}

.Body-projects .Work_previews>.Title{
  text-align:left;
  margin-top:0px;
  float:left;
  width:100%;
}

.Learn_more{
  color:#0784F8;
  text-align:right;
}

.Learn_more:hover{
  text-decoration:underline;
  cursor:pointer;
}

.hidden:not(.App-header){
  opacity:0!important;
  transition: opacity 1.2s!important;
}

.fade-in-element:not(.App-header){
  transition: opacity 1.2s!important;
  opacity:1;
}

.sketch_hidden{
  opacity:0!important;
  transition: all 1s!important;
  transform:scale(.993);
}


.sketch_visible{
  transition: all 1s!important;
  opacity:1;
  transform:scale(1);
}
/*
.SVGTEXT{
  fill:#0784F8;
  transition: all 1s!important;
}*/

.Foot-quote{
  position:relative;
  width:100%;
    font-size:1.02em;
    padding:50px;
    margin-top:80px;
    margin-bottom:10px;
    text-align:left;
    box-sizing:border-box;
    overflow:hidden;
}

.Foot-quote .Quote-text{
  margin-top:0px;
}





/*************************************************/
/***************** BODY PROJECTS *****************/
/*************************************************/


.Body-projects{
  padding:40px 0px;
  float:left;
  width:100%;
  padding-top:80px;
}

.Body-projects .Project.Col_3{
  width: calc(33.33% - 30px);
  float:left;
  padding:15px;
  padding-bottom:50px;
}
.Body-projects .Project.Col_3:nth-child(3n+1){
  padding-left:0px;
  padding-right:30px;
  clear:left;
}
.Body-projects .Project.Col_3:nth-child(3n+3){
  padding-right:0px;
  padding-left:30px;
  clear:right;
}

.Body-projects .Project:hover{
  cursor:pointer;
}

.Body-projects .Project:hover .Img{
  
  box-sizing:content-box;
  box-shadow: 0px 2px 22px -2px #38D7FF;
}

.Body-projects .Project:hover .Title, .Body-projects .Project:hover .Logo{
  border-color:#18E7FF;
  color:#18E7FF;
}

.Body-projects .Img{
  width:100%;
  transition: all .5s;
  border-radius:5px;
}

.Body-projects .Title{
  text-align:center;
  margin-top:-5px;
  font-size:1.1em;
  transition: all .5s;
}

.Body-projects .Project .Logo{
  width:30px;
  height:30px;
  background-size:cover;
  background-position: center center;
  background-repeat:no-repeat;
  display:inline-block;
  border: 1px solid #F0F0F0;
  border-radius:100px;
  margin-right:10px;
  position:relative;
  top:10px;
  transition: all .5s;
}



/*************************************************/
/********************** TEAM *********************/
/*************************************************/


.Body-team{
  margin-top:60px;
  overflow:hidden;
  width:100%;
}

.Body-team .Member{
  width: calc(50% - 30px);
  float:left;
  padding:15px;
  padding-bottom:100px;
  position:relative;
  opacity:1;
  transition: opacity .3s;
}

#App-body.faded .Member{
  opacity:0!important;
  transition: opacity .3s;
}

#App-body.faded .Work_previews{
  opacity:0!important;
  transition: opacity .3s;
}

#App-body .Member:nth-child(1){
  transition-delay:.06s;
}
#App-body .Member:nth-child(2){
  transition-delay:.12s;
}
#App-body .Member:nth-child(3){
  transition-delay:.18s;
}
#App-body .Member:nth-child(4){
  transition-delay:.24s;
}
#App-body .Member:nth-child(5){
  transition-delay:.3s;
}
#App-body .Member:nth-child(6){
  transition-delay:.36s;
}



.Body-team .Member:nth-child(2n+1){
  padding-left:0px;
  padding-right:30px;
  clear:left;
}
.Body-team .Member:nth-child(2n+2){
  padding-right:0px;
  padding-left:30px;
  clear:right;
}





.Body-team .Img{
  height:220px;
  width:220px;
  border-radius:500px;
  float:left;
  Object-fit: cover;
}

.Body-team .Title{
  float:left;
  width:280px;
  padding-left:40px;
}

.Body-team .Name{
  font-family: 'Cabin',sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top:20px;
  color: #0784F8;
}

.Body-team .Job_Title{
  font-family: 'Cabin',sans-serif;
  font-size: 18px;
  line-height: 21px;
  margin-top:8px;
  /* identical to box height */


  color: #0784F8;
}

.Body-team .Bio{
  font-family: Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-top:22px;

  color: #333639;

}





/*************************************************/
/****************** APP FOOTER *******************/
/*************************************************/

.Clients-section{
  border-top: 1px solid #22DAFA;
  border-image: linear-gradient(to left, white 0%, #22DAFA 50%, white 100%);
  border-image-slice: 1;
  border-width: 1px;
  width:100%;
  height:280px;
  padding-bottom:0px;
  float:left;
  margin-top:100px;
  padding-top:55px;
  position:relative;
  box-sizing:content-box;
}

.Clients-section .Title{
  position:relative;
  color:#22DAFA;
  margin:auto;
  width:210px;
  text-align:center;
  background:white;
  margin-top:-64px;
}


.Past-clients{
  width:100%;
  height:260px;
  margin-top:20px;
  background-size: contain;
  background-position: center center;
  background-repeat:no-repeat;
}


.App-footer {
  background: linear-gradient(0.2turn, #00E0FF, #D3F8FA);
  height:auto;
  color: white;
  text-align:left;
  float:left;
  width:100%;
  padding-top:80px;
}

.App-footer h1{
  color:white;
  text-shadow:1px 1px 0px #2599F8, 2px 2px 0px #2599F8;
  font-family:'Cabin',sans-serif;
  font-weight:bold;
  font-size:2.4em;
}

.Contact-overview p{
  color:#0784F8;
  max-width:380px;
}

.Contact-form input{
  width:calc(50% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #0784F8;
  color:#0784F8;
  height:60px;
  background:none;
  padding:10px 20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input::placeholder, .Contact-form textarea::placeholder{
  color:#33B5FF;
}

.Contact-form textarea{
  width:calc(100% - 20px);
  margin-right:20px;
  margin-bottom:20px;
  border: 1px solid #0784F8;
  background:none;
  color:#222222;
  height:100px;
  padding:20px;
  box-sizing:border-box;
  float:left;
  font-size:.8em;
  outline: none;
}

.Contact-form input[type="Submit"]{
  background:white;
  width:120px;
  border-radius:5px;
  height:50px;
  box-shadow: 1px 1px 0px #0784F8;
  float:right;
  transition: background-color .3s;
  cursor:pointer;
}

.Contact-form input[type="Submit"]:hover{
  background-color: #E8F8FF;
  color:#017BEC;
}

.Footer-notes{
  float:left;
  width:100%;
  padding:50px 0px;
  color:#0784F8;
}

.Footer-notes .left{
  float:left;
}

.Footer-notes .right{
  float:right;
}








/*************************************************/
/**************** @ MEDIA QUERIES ****************/
/*************************************************/


@media(max-width:1400px){
  .Page-container{
    max-width:1000px;
  }
  .App-header {
    height:calc(100vh - 120px);
  }

  .Body-team .Member{
    min-width:300px;
  }

  .Body-team .Img{
    height:220px;
    width:220px;
    margin:auto;
    display:block;
    Object-fit: cover;
    float:none;
  }

  .Body-team .Title{
    width:100%;
    text-align:center;
    padding-left:0px;
  }

  .Body-team .Bio{
    text-align:left;
    max-width:280px;
    margin-left:auto;
    margin-right:auto;
  }

  .Intro-text{
    bottom:55px;
  }
  
  .Intro-text p{
    left:520px;
    width:390px;
    font-size:1.3em;
    padding-left:60px;
    line-height:1.38em;
  }
  
  .Intro-text b{
    bottom:28px;
    font-size:1.8em;
    width:500px;
    line-height:1.4em;
  }

  .Quote-text{
    font-size:1.125em;
  }

  .Product-sketch{
    transform:scale(.87);
    top:-20px;
    right:-160px;
  }
  h1{
    font-size:2.3em;
  }
  .App-menu{
    font-size:1.1em;
  }

  
}

@media(max-width:1200px){
  .Page-container{
    max-width:800px;
  }

  .Product-sketch{
    /*width:120%;
    right:-140px;
    top:0px;
    transform:scale(.7);*/
    width: 120%;
    right: -140px;
    top: 20px;
    transform: scale(.85);
  }

  .App-menu .Menu-item{
    padding:0px 20px;
  }

  .App-body, .App-footer {
    padding-top:70px;
  }  

  .App-footer .Half-section{
    width:100%;
    float:left;
    box-sizing:border-box;
    padding:0px!important;
  }
  .App-footer .Half-section:nth-child(2){
    margin-top:40px;
  }

  .Body-projects .Project{
    width: calc(50% - 30px);
    float:left;
    padding:15px;
    padding-bottom:30px;
  }
  .Body-projects .Project:nth-child(2n+1){
    padding-left:0px;
    padding-right:30px;
    clear:left;
  }
  .Body-projects .Project:nth-child(2n+2){
    padding-right:0px;
    padding-left:30px;
    clear:right;
  }

  .Body-projects .Project.Col_3:nth-child(3n+2) {
    padding-left:15px;
    padding-right:15px;
    clear:none;
  }

  .Body-projects .Project.Col_3:nth-child(3n+1) {
    clear:left;
    float:left;
  }

  .Intro-text{
    bottom:55px;
  }
  
  .Intro-text p{
    left:435px;
    width:340px;
    font-size:1.1em;
    padding-left:50px;
    line-height:1.38em;
    border-width:2px;
  }
  
  .Intro-text b{
    bottom:20px;
    font-size:1.5em;
    width:400px;
    line-height:1.48em;
  }

  .Work_previews .left-section{
    width:50%;
  }

  .Work_previews .right-section{
    width:calc(50% - 60px);
  }

  .Work_previews .right-section .Project.Col_2{
    width:100%;
    padding:0px;
    padding-bottom:40px;
    max-width:280px;
    margin:auto;
    float:none;
  }

  .h1_2{
    width:100%;
  }

}

@media(max-width:1000px){
  .Page-container{
    max-width:700px;
  }

  .Intro-text{
    width:100%;
    max-width:380px;
  }
  
  .Intro-text p{
    width:100%;
    font-size:1.1em;
    padding:0px;
    line-height:1.38em;
    border-width:0px;
    position:relative;
    float:left;
    clear:both;
    left:0px;
  }
  .Intro-text b{
    bottom:20px;
    font-size:1.5em;
    width:100%;
    line-height:1.48em;
    position:relative;
    float:left;
    clear:both;
    left:0px;
  }

  .App-header {
    height:600px;
  }

  .App-logo {
    height: 60px;
    top:50px;
  }
  .Tag-line{
    top:95px;
  }
  .App-menu .Menu-item{
    padding:0px 15px;
  }

  h1{
    font-size:40px;
  }

  .Product-sketch{
    width:1000px;
    padding-right:5%;
    right:0px;
    top:0px;
    transform:scale(1);
    transform-origin:left;
  }

  #sketch_wrapper{
    position:absolute;
    top:350px;
    right:0px;
    overflow:hidden;
    height:400px;
    width:100%;
  }

  .O_1, .T_1, .A_1, .O_2, .T_2, .A_2, .O_3, .T_3, .A_3{
    display:none!important;
  }

  .About .Quote-text{
    margin-top:500px;
  }

}

@media(max-width:800px){


  .App {
    font-family: 'Founders Grotesk';
    font-size:16px;
  }

  .Page-container{
    margin-left:40px;
    margin-right:40px;
    width:calc(100% - 80px);
  }


  .Menu-inner{
    padding-right:40px;
    min-width:650px;
  }
  .Half-section{
    width:100%;
    float:left;
    box-sizing:border-box;
    padding:0px;
  }

  .Body-header .Half-section{
    padding:0px;
    padding-top:20px;
  }

  .Half-section.Team p, .Half-section.About p {
    width: 100%;
  }
  .App-body, .App-footer {
    padding-top:60px;
  }
  .Footer-notes .left, .Footer-notes .right{
    float:left;
    width:100%;
    text-align:center;
    margin-top:10px;
  }
  .Body-projects .Project{
    width: 100%;
    float:left;
    padding:15px 0px;
    padding-bottom:30px;
  }

  .Body-projects .Project.Col_3:nth-child(2n+2) {
    width: calc(50% - 15px);
    padding-right:0px;
    padding-left:15px;
    clear:right;
  }

  .Body-projects .Project.Col_3:nth-child(2n+1) {
    width: calc(50% - 15px);
    padding-left:0px;
    padding-right:15px;
    clear:none;
  }

  .Body-team .Member .Name{
    margin-top:0px;
  }

  .Body-team .Member{
    float:none;
    margin:auto;
    overflow:hidden;
    padding:0px!important;
    padding-bottom:50px!important;
  }

  .Body-team .Title{
    padding-top:20px;
  }

  /*.Product-sketch{
    display:none;
  }*/

  .Cover-img{
    right:50%;
    margin-right:-175px;
    top:350px;
    width:320px;
  }
  .hello_code{
    font-size:.85em;
  }
  .hello_brand{
    font-size:1.1em;
  }

  .Tag-line{
    font-size:.94em;
  }

  .Product-sketch{
    width:1000px;
    right:-200px;
    top:0px;
    transform:scale(.9);
    margin-right:30%;
    padding:0px;
    transform-origin:center;
  }

  #sketch_wrapper{
    top:220px;
  }
  
  .Body-projects{
    padding-top:40px;
  }

  .Quote-text{
    width:100%;
    font-size:1em;
    padding:50px;
    margin-top:60px;
    text-align:left;
  }
  .Quote-text .leftCorner{
    left:20px;
  }
  .Quote-text .rightCorner{
    right:20px;
  }
  
}

@media(max-width:600px){

  #Main_nav.sticky{
    position:fixed;
    top:0px;
    z-index:100;
    height:75px;
    box-shadow:0px 4px 0px rgba(0,0,0,.03);
  }
  
  #Main_nav.sticky .Menu-item{
    margin-top:8px;
    line-height:67px;
  }
  
  .App-logo {
    height: 45px;
    top:50px;
  }

  .Past-clients-mobile{
    width:100%;
    height:400px;
    margin-top:-45px;
    background-size: contain;
    background-position: center center;
    background-repeat:no-repeat;
  }
  .Past-clients{
    display:none;
  }

  .Project-section iframe{
    height:300px;
  }
  

  .Work_previews .left-section{
    width:100%;
  }

  .Work_previews .right-section{
    width:100%;
    padding-left:0px;
    padding-top:40px;
  }

  .Intro-text br{
    display:none;
  }

  .Product-sketch{
    width:1000px;
    right:-320px;
    top:0px;
    transform:scale(.7);
    margin-right:40%;
    padding:0px;
    transform-origin:center;
  }

  #sketch_wrapper{
    top:250px;
  }

  .About .Quote-text{
    margin-top:400px;
  }

}


@media(max-width:450px){

  .Intro-text b{
    font-size:1.3em;
  }

  .Past-clients-mobile{
    height:350px;
  }

  .Clients-section{
    padding-top:0px;
    margin-top:70px;
  }

  .Clients-section .Title{
    margin-top:-8px;
  }

  .Project-section iframe{
    height:250px;
  }

  .Foot-quote{
    padding:0px;
    padding-bottom:40px;
  }

  .Product-sketch{
    width:1000px;
    right:-320px;
    top:0px;
    transform:scale(.6);
    margin-right:30%;
    padding:0px;
    transform-origin:center;
  }

  #sketch_wrapper{
    top:250px;
  }

  .About .Quote-text{
    margin-top:320px;
  }

  .Body-projects .Project.Col_3 {
    width: 100%!important;
    padding:30px 0px!important;
    clear:right;
  }

}